export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  if (config.public.CC_ID && config.public.ENV_NAME === 'production') {
    window.setTimeout(() => {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = config.public.CC_ID;

      /* eslint-disable */
      (function () {
        var d = document;
        var s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
      $crisp.push(['safe', true])
      /* eslint-enable */
    }, 10000)
  }
})