const localStorageKeys = {
  sv: {
    selectedAirport: 'rbAirport',
  },
  zz: {
    favourites: 'solresorFavourites',
  },
  auth: {
    token: 'rb.auth.token',
    currentRoute: 'rb.auth.url',
    action: 'rb.auth.action',
    method: 'rb.auth.method',
    lock: 'rb.auth.lockstatus',
  },
  hideNewsletter: 'hideNL',
  extraLuggage: 'luggageStorage',
}

export default localStorageKeys

export { localStorageKeys }
