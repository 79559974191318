import { isReactive, toRaw } from 'vue'

/**
 * Checks if element is vue/nuxt native wrapper element
 * @param {*} type vnode.type (slot, context)
 * @returns boolean
 */
export const isNativeWrapperElement = (type: { [key: string]: any }) => {
  const dataType = typeof type

  return (
    dataType === 'symbol' ||
    (dataType === 'object' && ['AsyncComponentWrapper', 'ClientOnly'].includes(type?.name))
  )
}

/**
 * Returns slot components
 * @param {object} slot either vue native or custom components
 * @returns Component
 */
const extractCustomComponents = (slot: { [key: string]: any }) => {
  if (!isNativeWrapperElement(slot.type) || !slot.children || typeof slot.children === 'string') {
    return slot
  }

  return slot.children.flatMap(extractCustomComponents)
}

/**
 * Vue seems to group slot components/non-html elements into a single component for some reason
 */
export const unwrapSlot = (slot: { [key: string]: any }) => slot
  .reduce((unwrappedSlot: { [key: string]: any }[], slotItem: { [key: string]: any }) => {
    const slotContent = extractCustomComponents(slotItem)

    if (Array.isArray(slotContent)) {
      return [
        ...unwrappedSlot,
        ...slotContent,
      ]
    }

    return [
      ...unwrappedSlot,
      slotContent,
    ]
  }, [])

/**
 * Vue seems to group slot components/non-html elements into a single component for some reason
 */
export const slotLength = (slots: { [key: string]: any }) => slots
  .reduce((slotLength: number, slot: { [key: string]: any }) => (
    slotLength +
    unwrapSlot([slot]).length
  ), 0)

export const getCustomComponentParent: any = (context: { [key: string]: any }) => isNativeWrapperElement(context.parent.type)
  ? getCustomComponentParent(context.parent)
  : context.parent

export function isObject (value: unknown): boolean {
  return value !== null && !Array.isArray(value) && typeof value === 'object'
}

export function getRawData<T> (data: T): T {
  return isReactive(data) ? toRaw(data) : data
}

export function toDeepRaw<T> (data: T): T {
  const rawData = getRawData<T>(data)

  for (const key in rawData) {
    const value = rawData[key]

    if (!isObject(value) && !Array.isArray(value)) {
      continue
    }

    rawData[key] = toDeepRaw<typeof value>(value)
  }

  return structuredClone(rawData)
}

export const slotHasContent = (name: any, slots: any) => {
  const slot = slots?.[name]

  if (typeof slot !== 'function') {
    return false
  }

  const slotElements = slot()

  if (!slotElements) {
    return false
  }

  if ((slotElements || []).length === 0) {
    return false
  }

  if (slotElements.map((x: any) => x?.tag || x?.children || x?.data).filter(Boolean).length === 0) {
    return false
  }

  return true
}
