
export const isSafari = (
  process.browser
  && /Safari/.test(navigator.userAgent)
  && !/Chrom/.test(navigator.userAgent)
  && !/Edge/.test(navigator.userAgent)
  && !window.MSStream
)

export const isIOSSafari = (
  process.browser
  && /\b(iPad|iPhone|iPod)\b/.test(navigator.userAgent)
  && /Safari/.test(navigator.userAgent)
  && !/Chrom/.test(navigator.userAgent)
  && !/Edge/.test(navigator.userAgent)
  && !window.MSStream
)

export const isMobile = (
  process.browser &&
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
)
