import { isLocaleSol, isLocaleRolf } from '@packages/locale/utils/market.ts'

export default defineNuxtPlugin((nuxtApp) => {
  const isSol = isLocaleSol(nuxtApp.$config.public.locale)
  const isRolf = isLocaleRolf(nuxtApp.$config.public.locale)

  return {
    provide: {
      isSol,
      isRolf,
    },
  }
})