
export const LOCALES_DEFAULT_AIRPORT = {
  sv: {
    airport: 'GOT',
    airport_name: 'Göteborg-Landvetter',
  },
  no: {
    airport: 'OSL',
    airport_name: 'Oslo-Gardemoen',
  },
  zz: {
    airport: 'STO',
    airport_name: 'Stockholm',
  },
  da: {
    airport: 'CPH',
    airport_name: 'Köpenhamn',
  },
}

export const OPEN_PURCHASE_LOCALES = ['zz']

export const LOCALES_WITH_LOGIN = ['sv']

export const LOCALES_WITH_FAVOURITES = ['sv', 'zz']

export const LOCALES_WITH_AIRPORT_SPECIFIC_NEWSLETTER = ['zz']

export const LOCALES_WITHOUT_CONNECTIONS = ['no', 'da']

export const LOCALES_WITH_STARTPAGE_CUSTOM_CAMPAIGN = ['sv', 'no']

export const LOCALES_WITH_BLOG = ['sv', 'no']
