import { loginWithActionEvent } from '@layers/web/constants/events'
import contexts from '@layers/web/constants/contexts'

export const loginWithAction = (name, payload) => ({
  name,
  ...payload,
})
export const loginWhileTogglingFavourite = (tripTypeId, message) => ({
  action: loginWithAction(loginWithActionEvent.toggleFavourite, {
    tripTypeId,
  }),
  feedback: createAuthFeedback(message),
})

export const AuthPages = {
  default: 'default',

  logout: 'logout',
  login: 'login',
  signup: 'signup',
  whatsThis: 'whatsThis',
  forgot: 'forgot',

  myBookings: 'my-bookings',
  favourites: 'favourites',
  settings: 'settings',
}
export const getAuthPaths = locale => ['en', 'pl', 'zh', 'zh-cn'].includes(locale) ? {
  [AuthPages.signup]: 'sign-up',
  [AuthPages.whatsThis]: 'whats-this',
  [AuthPages.login]: 'log-in',
  [AuthPages.logout]: 'log-out',
  [AuthPages.forgot]: 'forgot-password',
} : {
  [AuthPages.signup]: 'logga-in?state=registrera',
  [AuthPages.whatsThis]: 'logga-in?state=vad-ar-det-har',
  [AuthPages.login]: 'logga-in',
  [AuthPages.logout]: 'logga-ut',
  [AuthPages.forgot]: 'logga-in?state=glomt-losenord',
}
export const authPathsUtil = (key, locale, urls) => {
  const { logout, login, signup, forgot, whatsThis } = getAuthPaths(locale)

  switch (key) {
    case AuthPages.logout: {
      return urls['auth-callback'] + '?' + logout
    }
    case AuthPages.login: {
      return urls.auth + '/' + login
    }
    case AuthPages.signup: {
      return urls.auth + '/' + signup
    }
    case AuthPages.favourites: {
      return urls.auth + urls.authSlug.favourites
    }
    case AuthPages.whatsThis: {
      return urls.auth + '/' + whatsThis
    }
    case AuthPages.forgot: {
      return urls.auth + '/' + forgot
    }
    case AuthPages.settings: {
      return urls.auth + urls.authSlug.settings
    }
    case AuthPages.default:
    case AuthPages.myBookings:
    default: {
      return urls.auth + urls.authSlug['my-bookings']
    }
  }
}

export const createAuthFeedback = (message, code = 'unknown', type = contexts.info, action = null, actionText = null) => ({
  code,
  message,
  action,
  actionText,
  type,
})

export const getGravatarByEmail = (email) => {
  const hashedMail = md5(email)
  return `https://www.gravatar.com/avatar/${hashedMail}?d=mp`
}
