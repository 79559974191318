
export default defineNuxtPlugin(() => {
  const userStore = useUserStore()
  const {
    isLoggedIn,
    user,
  } = storeToRefs(userStore)

  let logoutAfterExpireTimeout

  watch(isLoggedIn, (value) => {
    clearTimeout(logoutAfterExpireTimeout)
    if (value && user.value?.exp) {
      logoutAfterExpireTimeout = setTimeout(userStore.clearUserForLogout, Math.max((user.value?.exp * 1000) - Date.now(), 0))
    }
  }, { immediate: true })
})
