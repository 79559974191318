export const IMAGE_SIZE_WIDTH_STEP_PX = 500
export const IMAGE_SIZE_HEIGHT_STEP_PX = 500
export const IMAGE_SIZE_MAX_PX = 2000

const findClosestStep = (raw, step) => (raw % step === 0)
  ? raw
  : (Math.ceil(raw / step) || 1) * step

export const findClosestSizeStep = ({ h, w }) => {
  const calculated = {}

  if (h !== undefined) {
    calculated.h = Math.min(
      IMAGE_SIZE_MAX_PX,
      findClosestStep(h, IMAGE_SIZE_HEIGHT_STEP_PX) || IMAGE_SIZE_HEIGHT_STEP_PX
    )
  }
  if (w !== undefined) {
    calculated.w = Math.min(
      IMAGE_SIZE_MAX_PX,
      findClosestStep(w, IMAGE_SIZE_WIDTH_STEP_PX) || IMAGE_SIZE_HEIGHT_STEP_PX
    )
  }

  return calculated
}

export const findContainerAwareImageSize = ($container) => {
  const containerHeight = $container?.offsetHeight || window?.innerHeight || 0
  const containerWidth = $container?.offsetWidth || window?.innerWidth || 0

  let size = containerWidth
  let sizeDimension = 'w'
  if (containerHeight >= containerWidth) {
    size = containerHeight
    sizeDimension = 'h'
  }

  const h = Math.min(IMAGE_SIZE_MAX_PX, findClosestStep(containerHeight, IMAGE_SIZE_HEIGHT_STEP_PX))
  const w = Math.min(IMAGE_SIZE_MAX_PX, findClosestStep(containerWidth, IMAGE_SIZE_WIDTH_STEP_PX))

  return {
    w, h, size, sizeDimension,
  }
}

export const getDpr = () => window?.devicePixelRatio || null

export const parseDpr = () => {
  const dpr = getDpr()
  if (dpr === null) {
    return 'auto'
  } else if (dpr <= 1) {
    return '1.0'
  }
  return `${Math.floor(dpr)}.0`
}

export const removeImageType = image => `${image}`
  .replace('.jpg', '')
  .replace('.jpeg', '')
  .replace('.gif', '')
  .replace('.png', '')
