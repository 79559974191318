
export const SCREEN_WIDTH_SIZES = {
  xs: 320,
  sm: 576,
  md: 768 + (isSafari ? 15 : 0), // Such a weird bug
  lg: 992,
  xl: 1200,
  mxl: 1600,
  xxl: 1920,

  mobile: 576,
  tablet: 992,
  ipad: 1024,
  notebook: 1366,
}
export { SCREEN_WIDTH_SIZES as SIZES }
