// Translated
// Migrated
<template lang="pug">
.trip-item.rounded-box
  article.article-block(:class="[aspectRatio && `aspect-ratio__${aspectRatio}`]")
    nuxt-link(:to="trip?.url || '#'" :class="[aspectRatio && `aspect-ratio__content`]")
      ResponsiveImage.article-block-img.d-flex.flex-column.trip-image.w-100.h-100.position-absolute(
        :image="trip?.main_picture_cloudinary",
        :alt="trip?.title || $t('siteTitleBrand')",
        zoomable
        lazy
        :skeleton="loading"
      )
        img.position-absolute.trip-partner-logo.partner-logo.d-block.mt-5(
          v-if="partnerLogoUrl",
          :src="partnerLogoUrl",
          :alt="$t('siteTitleBrand')",
          loading="lazy"
        )

        .position-absolute.text-white.top-0.mt-3.right-0.d-flex.align-items-end(
          v-for="version in ['visible', 'hidden']"
          :ref="version === 'hidden' ? 'virtualPriceContainer' : null"
          :key="version"
          :class="[version === 'hidden' ? 'invisible' : 'd-inline-flex', { 'flex-column': version === 'visible' && isPriceBlockBreakline }]"
        )
          .mt-2.bg-blue.d-inline-flex.align-items-center(
            v-if="trip?.price_min"
            :class="isPriceBlockBreakline ? 'h-px-30 px-3' : 'h-px-40 pl-3 pr-1'"
          )
            .text-nowrap
              fa(:icon="trip?.flight_trip ? 'plane' : 'bus'")
              span.ml-2(v-if="days" data-i18n="daysText") {{ $t('daysText', { n: days }) }}
          .mt-2.bg-blue.d-inline-flex.align-items-center(
            v-if="trip.price_min"
            :class="isPriceBlockBreakline ? 'h-px-30 px-3' : 'h-px-40 pr-3'"
          )
            .text-nowrap
              small.ml-1(data-i18n="shortFromPrice") {{ $t('shortFromPrice') }} {{ ' ' }}
              small.strikethrough.before-border-white.mr-1(v-if="isDiscounted") {{ $n(trip.price_min_without_discount) }}
              span(:class="{ 'font-weight-bold': isDiscounted }") {{ $n(trip.price_min) }}

        .position-absolute.top-left
          FavouriteIcon(:id="trip?.id")

        .mobile-trip-title
          .d-block.d-lg-none.mt-auto.d-flex.justify-content-between.align-items-end
            div
              .d-block.mb-2.ml-2.pl-1
                span.text-white.bg-blue.rounded-4.px-3.py-1(v-if="trip?.new_trip") {{ $t('newTrip') }}
              .article-block--title.m-0 {{ trip?.title }}
              p.article-block--subtitle.mb-1.d-block.d-lg-none {{ trip?.subtitle }}
              .d-flex.d-lg-none.flex-row.justify-content-start.article-block--days
                .ml-0.ml-lg-3(
                  v-if="trip?.reviews_average && trip?.reviews_average.avg"
                )
                  LazyStars.pr-2.pb-1(
                    :score="trip?.reviews_average.avg"
                    classes="product-price-box__stars"
                  )
                span.text-center.mr-3(data-i18n="departuresText") {{ $t('departuresText', trip?.departures) }}
                span.text-center(data-i18n="daysText") {{ $t('daysText', { n: days }) }}

        aside.trip-description.article-block-footer.d-none.d-lg-flex.justify-content-between
          .trip-description-title-block.w-100(v-if="loading")
            .w-100
              b-skeleton(as="h5" height="1.5rem" width="30%")
              b-skeleton(as="p" width="60%")
            .w-100
              b-skeleton.d-block(as="span" width="45%")
          .trip-description-title-block.d-flex.flex-column.justify-content-start.w-100(v-else)
            .d-flex.flex-column.justify-content-start
              .d-inline.mb-2
                span.text-white.bg-blue.rounded-4.px-3.py-1(v-if="trip?.new_trip") {{ $t('newTrip') }}
              .article-block--title.m-0 {{ trip?.title }}
              p.article-block--subtitle {{ trip?.subtitle }}
            .d-flex.flex-row.justify-content-start.align-items-center
              .ml-auto.ml-lg-0.d-flex.align-items-center(
                v-if="trip?.reviews_average && trip?.reviews_average.avg"
              )
                LazyStars.pr-2.pb-1(
                  :score="trip?.reviews_average.avg"
                  classes="product-price-box__stars"
                )
              span.pr-0 {{ $t('departuresText', trip?.departures) }}
</template>

<script>
import throttle from 'lodash.throttle'

export default defineNuxtComponent({
  name: 'Trip',

  middleware: 'auth',

  auth: false,

  props: {
    trip: {
      type: Object,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    aspectRatio: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      throttledShouldPriceBreakline: null,
      isPriceBlockBreakline: false,
      priceBlockBreaklineOffsetPx: 50,
    }
  },

  computed: {
    days () {
      const daysMin = this.trip?.days_min || 0
      const daysMax = this.trip?.days_max

      return daysMin === daysMax ? daysMin : `${daysMin} - ${daysMax}`
    },

    partnerLogoUrl () {
      return this.trip?.partner_logo ? formatCloudinaryImagePath(this.trip?.partner_logo, {
        transformation: { custom: 'w_350,c_thumb' },
        isSol: this.$isSol,
      }) : null
    },

    isDiscounted () {
      return this.trip?.price_min < this.trip?.price_min_without_discount
    },
  },

  mounted () {
    this.shouldPriceBlockBreakline()
    this.throttledShouldPriceBreakline = throttle(this.shouldPriceBlockBreakline, 200)
    window.addEventListener('resize', this.throttledShouldPriceBreakline)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.throttledShouldPriceBreakline)
  },

  methods: {
    shouldPriceBlockBreakline () {
      // !TODO break this out into its own reusable component
      if (this.$refs.virtualPriceContainer?.length) {
        const {
          offsetWidth: priceContainerWidth,
          parentNode: {
            offsetWidth: priceParentWidth,
          },
        } = this.$refs.virtualPriceContainer[0]

        if (priceContainerWidth + this.priceBlockBreaklineOffsetPx > priceParentWidth) {
          this.isPriceBlockBreakline = true
        } else {
          this.isPriceBlockBreakline = false
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.top-left {
  top: 0;
  left: 0;
  z-index: 1;
}
.partner-logo {
  height: auto;
  width: 80%;
  max-width: 300px;
  left: 50% !important;
  transform: translateX(-50%);

  @media (max-width: $lg) {
    padding: 10px 15px 15px 10px;
  }
}
</style>
