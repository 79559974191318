
export const useAlgoliaHelpers = () => {
  // return {tripSearchIndex: useLocaleStore().locale + '_rolfsbuss'}

  const { locale } = useLocaleStore()

  const localesTripSearchIndices = {
    'sv': 'sv_rolfsbuss',
    'da': 'da_rolfsbuss',
    'no': 'no_rolfsbuss',
    'zz': 'zz_rolfsbuss',
    'zt': 'zz_rolfsbuss',
    'xx': 'xx_rolfsbuss',
  }

  const tripSearchIndex = localesTripSearchIndices[locale]

  return {
    tripSearchIndex,
  }
}
