<template lang="pug">
.b-skeleton.b-skeleton-img.b-skeleton-animate-wave(
  v-if="noAspect",
  :style="aspectStyle"
)

.b-aspect.d-flex(v-else)
  .b-aspect-sizer.flex-grow-1(:style="aspectStyle")
  .b-aspect-content.flex-grow-1.w-100.mw-100(style="margin-left: -100%")
    .b-skeleton.b-skeleton-img.b-skeleton-animate-wave
</template>

<script>
export default defineNuxtComponent({
  props: {
    noAspect: {
      type: Boolean,
      default: false,
    },

    aspect: {
      type: String,
      default: null,
    },

    height: {
      type: String,
      default: null,
    },

    width: {
      type: String,
      default: null,
    },
  },

  computed: {
    aspectStyle () {
      const style = {}

      if (!this.noAspect) {
        if (!this.aspect) {
          style['padding-bottom'] = '56.25%'
        } else if (this.aspect === '3:1') {
          style['padding-bottom'] = '33.33%'
        }
      }

      if (this.height) {
        style.height = this.height
      }
      if (this.width) {
        style.width = this.width
      }

      return style
    },
  },
})
</script>
