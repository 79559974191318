// fuse.js plugin
import Fuse from 'fuse.js'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      fuse: (list: Array<any>, options: object) => new Fuse(list, options),
    },
  }
})
