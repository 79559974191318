<template lang="pug">
div
  NuxtPage

  slot

  LazyCognitoContainer(v-if="locale === 'sv'")
  CookieConsent
  NewsletterPopup(v-if="locale === 'no'")

  AgentComponent
    LazyAgentCall
    LazyTranslationTools
</template>

<script setup>
const { locale } = useLocaleStore()
</script>