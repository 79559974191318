import { joinURL } from 'ufo'
import defu from 'defu'

import localStorageKeys from '@layers/web/constants/local-storage-keys'
import { API_DISALLOWED_FILETYPES } from '@layers/web/constants/api-fetch'

/**
 * @param {string} request Api path without env API_BASE_PATH
 * @param {$fetch options} opts throwOnFail Default true, if false, return error object instead of throwing | externalUrl Default false if true, request will be made to external URL use full URL as request | addToken Default false, if true, add token to body
 * @returns {
 *   ...fetch response,
 *   data: data.json
 * }
 */
export const apiFetch = async (request, opts = {}) => {
  // Prevent catch-all routes from trying to fetch files from API
  const { throwOnFail, externalUrl, timeout, simple } = {
    throwOnFail: true,
    externalUrl: false,
    timeout: 60 * 1000,
    simple: false,
    ...opts,
  }

  if (API_DISALLOWED_FILETYPES.some(filetype => request.endsWith(filetype))) {
    if (throwOnFail) {
      throw new Error('Not found: ' + request)
    }

    return {
      data: {},
      error: {},
    }
  }

  const { API_URL, API_BASE_PATH } = useConfigStore()

  const fetchOptions = {
    headers: {},
    timeout,
  }

  let rootStore
  if (!simple) {
    rootStore = useRootStore()

    const c = rootStore.rolfsCookie
    if (c) {
      fetchOptions.headers['X-ROLFS-RF'] = c
    }

    if (rootStore.agentCookie && rootStore.agentCookie.code) {
      fetchOptions.headers['X-ROLFS-A'] = rootStore.agentCookie.code
      fetchOptions.headers['X-ROLFS-ATIME'] = rootStore.agentCookie.currentDate
    }
  }

  if (import.meta.browser) {
    fetchOptions.headers['X-HREF'] = location.href
  }

  // Check for method and addToken in opts
  if (opts.method === 'post' && opts.addToken === true) {
    const token = localStorage.getItem(localStorageKeys.auth.token)

    if (token) {
      fetchOptions.body = {
        token,
      }
    }
  }

  if (process.env.NODE_ENV === 'development') {
    console.info(
      externalUrl
        ? `[apiFetch] Making external request to: ${request}`
        : `[apiFetch] Making request to: ${API_BASE_PATH}${request}`
    )
  }

  const mergedFetchOptions = defu(opts, fetchOptions)

  let url
  if (externalUrl) {
    url = request
  } else if (import.meta.browser) {
    url = `${API_BASE_PATH}${request}` // PROXY
  } else {
    url = joinURL(API_URL, API_BASE_PATH) + request // API URI
  }

  let response = null
  try {
    response = await $fetch.raw(url, mergedFetchOptions)
  } catch (error) {
    if (process.NODE_ENV === 'development') {
      console.error('[apiFetch] Request failed: ', error)
    }

    if (throwOnFail) {
      throw error
    } else {
      return { error }
    }
  }

  if (!simple && response.headers.get('x-rolfs-rf') && !rootStore.rolfsCookie) {
    const c = response.headers.get('x-rolfs-rf')

    try {
      rootStore.SET_ROLFSCOOKIE(c)
    } catch (e) {
      // Cannot set headers after they are sent to the client
    }
  }

  return {
    ...response,
    data: response._data,
  }
}