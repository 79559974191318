import { CHARTER_MARKETS, MARKETS } from '@packages/locale/constants/markets.ts'

export const isLocaleSol = (locale: string) => CHARTER_MARKETS.includes(locale)
export const isLocaleRolf = (locale: string) => !CHARTER_MARKETS.includes(locale)
export const localeToMarket = (locale: string) => {
  const [_, market] = Object.entries(MARKETS)
    .find(([_, brand]) => brand.locale === locale)

  return market
}
