import { LOCALES_DEFAULT_AIRPORT } from '@layers/web/constants/locale.js'

export const useLocale = () => {
  const { public: { locale } } = useRuntimeConfig()
  const localeStore = useLocaleStore()

  const defaultAirport = LOCALES_DEFAULT_AIRPORT[locale]
  const urls = localeStore.urls

  return {
    urls,
    defaultAirport,
  }
}