import type { Pinia } from 'pinia'
import { defineNuxtPlugin } from '#app'

import { createPersistedState } from '@packages/persiststate/runtime/pinia-plugin.ts'

export default defineNuxtPlugin((nuxtApp) => {
  const pinia = nuxtApp.$pinia as Pinia

  pinia.use(createPersistedState({
    storage: persistedState.localStorage,
    key: key => `${key}State`,
  }))
})
