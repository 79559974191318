import validate from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/validate.js";
import previous_45route_45global from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/middleware/previous-route.global.js";
import route_45global from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/middleware/route.global.js";
import manifest_45route_45rule from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  previous_45route_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/middleware/auth.js")
}