
export type LocaleLangInfo = {
  code: string
  lang: string
  name: string
  iso: string
}

export const LOCALES_LANG_INFO: { [key: string]: LocaleLangInfo } = {
  da: {
    code: 'da',
    lang: 'da',
    name: 'Dansk',
    iso: 'da-DK',
  },
  no: {
    code: 'no',
    lang: 'nb',
    name: 'Norsk',
    iso: 'nb-NO',
  },
  en: {
    code: 'en',
    lang: 'en',
    name: 'English',
    iso: 'en-GB',
  },
  pl: {
    code: 'pl',
    lang: 'pl',
    name: 'Polski',
    iso: 'pl-PL',
  },
  'zh-cn': {
    code: 'zh-cn',
    lang: 'zh-cn',
    name: '中國',
    iso: 'zh-CN',
  },
  sv: {
    code: 'sv',
    lang: 'sv',
    name: 'Svenska',
    iso: 'sv-SE',
  },
}

export const SWEDISH_LOCALES = ['sv', 'zz']
