
export const CHARTER_MARKETS = ['zz']

export const MARKETS = {
  Solresor: {
    locale: 'zz',
    name: 'Solresor',
    themeColor: '#fecb21',
  },
  Rolfsbuss: {
    locale: 'sv',
    name: 'Rolfsbuss',
    themeColor: '#004b9c',
  },
  Rolfsreiser: {
    locale: 'no',
    name: 'Rolfsreiser',
    themeColor: '#004b9c',
  },
  RolfsbussDK: {
    locale: 'da',
    name: 'Rolfsbuss',
    themeColor: '#004b9c',
  },
}
