export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('rtg-tooltip', (el, binding) => {
    const text = binding.value?.text || binding.value
    el.setAttribute('data-tooltip', text)
    el.classList.add('v-popper__tooltip')

    if (getComputedStyle(el).position === 'static') {
      el.style.position = 'relative'
    }

    const element = document.createElement('div')
    element.innerHTML = text
    element.classList = 'v-popper__tooltip__inner'

    const position = binding?.value?.position || 'top'
    el.classList.add(`v-popper--${position}`)

    el.appendChild(element)
  })

  nuxtApp.vueApp.directive('click-outside', {
    /* eslint-disable-next-line no-unused-vars */
    beforeMount (element, binding) {
      element.clickOutsideEvent = function (event) { //  check that click was outside the el and his children
        // only listen to left click
        if (event.which !== 1) {
          return
        }

        let fn = binding.value
        let ignore = null

        if (typeof binding.value === 'object') {
          fn = binding.value.fn
          ignore = document.querySelector(binding.value.ignore)
        }

        if (!(element === event.target || element.contains(event.target)) && (event.target !== ignore && !ignore?.contains(event.target))) { // and if it did, call method provided in attribute value
          fn()
          // binding.value(); run the arg
        }
      }
      document?.body?.addEventListener('mouseup', element.clickOutsideEvent)
    },

    beforeUnmount (element) {
      document?.body?.removeEventListener('mouseup', element.clickOutsideEvent)
    },
  })

  nuxtApp.vueApp.directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted (el) {
      // Focus the element
      el.focus()
    },
  })
})
