/**
 * Same function as gtag
 * Usable when gtag isn't initialized
 */
export function pushDataLayer () {
  if (process.browser && !window.dataLayer) {
    window.dataLayer = []
  }
  window.dataLayer.push(arguments)
}

export const getUserConsentStatus = (rbcs) => {
  if (rbcs === undefined) {
    let jsonRbcs

    try {
      jsonRbcs = window.localStorage.getItem('rbcs')
    } catch {
      // localStorage no access or space
    }

    rbcs = jsonRbcs
      ? JSON.parse(jsonRbcs)
      : {}
  }

  return {
    all: rbcs.all || (rbcs.f && rbcs.a && rbcs.p),
    any: rbcs.all || rbcs.f || rbcs.a || rbcs.p,
    advertising: rbcs.a || rbcs.all,
    performance: rbcs.p || rbcs.all,
    functional: rbcs.f || rbcs.all,
    cookieVersion: rbcs.v,
    timestamp: rbcs.t,
  }
}
