import events from '@layers/web/constants/events.js'

function initHJ () {
  const { public: { HJ_ID } } = useRuntimeConfig()

  if (!HJ_ID) {
    return
  }

  /* eslint-disable */
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={ hjid: HJ_ID, hjsv: 6 };
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    r.defer = true;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  /* eslint-enable */
}

export default defineNuxtPlugin(() => {
  const { userConsentStatus } = useUserConsent()
  if (userConsentStatus.value.performance) {
    initHJ()
  }

  window.addEventListener(events.rbcsChange, (e) => {
    if (!e.detail.userConsentStatus.performance) {
      return
    }

    initHJ()
  })
})
