
export default {
  methods: {
    days (result) {
      if (result.days) {
        return result.days
      }

      const daysMin = result.days_min || 0
      const daysMax = result.days_max

      return daysMin === daysMax ? daysMin : `${daysMin} - ${daysMax}`
    },
  },
}
