<template lang="pug">
NuxtLoadingIndicator(:color="loadingColor")
.position-relative(:class="wrapperClass")
  App
    slot
</template>

<script setup>
useConfigStore()
const route = useRoute()
const { t } = useI18n()

const headers = useRequestHeaders(['user-agent'])
const userAgent = headers['user-agent'] || ''
const whitelabelStore = useWhitelabelStore()

const { $isSol } = useNuxtApp()

onServerPrefetch(async () => {
  const rootStore = useRootStore()

  if ($isSol && route.query?.wl) {
    whitelabelStore.SET_WHITE_LABEL(route.query?.wl)
  }

  await rootStore.handleAgentCookie(route.query?.a)

  const fetchPromises = [
    rootStore.fetchAgentCall(),
    rootStore.fetchTopNavbar(),
    rootStore.fetchFooter(),
    rootStore.fetchAlert(),
  ]

  if (!isMobileUserAgent(userAgent) && !$isSol) {
    fetchPromises.push(rootStore.fetchTripTypesSidebar())
  }

  try {
    await Promise.all(fetchPromises)
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: error.message,
    })
  }
})

onMounted(async () => {
  if (isMobileUserAgent() && !$isSol) {
    await rootStore.fetchTripTypesSidebar(false)
  }
  whitelabelStore.checkAgentCookie()
})

const rootStore = useRootStore()

const {
  modalActive,
} = storeToRefs(rootStore)
const { locale } = useLocaleStore()

const whitelableColor = computed(() => {
  return whitelabelStore.getWhitelabel ? whitelabelStore.getWhitelabel.primaryColor : '#fecb21'
})
const whitelableTextColor = computed(() => {
  return whitelabelStore.getWhitelabel ? 'white' : 'black'
})

const localeLangInfo = localeToLangInfo(locale)

useHead({
  htmlAttrs: {
    lang: () => localeLangInfo.code,
  },
})

useSchemaOrg([
  defineWebSite({
    description: t('homeDescription'),
    inLanguage: localeLangInfo.iso,
  }),
])

const wrapperClass = computed(() => {
  const classes = []

  if (!modalActive.value) {
    classes.push('overflow-clip')
  }

  return classes.join(' ')
})

const loadingColor = computed(() => isSol() ? '#fecb21' : '#065BA2')
</script>

<style lang="scss">
.btn-orange {
  background: v-bind(whitelableColor) !important;
  color: v-bind(whitelableTextColor) !important;
}
</style>
