
export default defineNuxtPlugin(() => {
  const noscriptTag = document.createElement('noscript')

  noscriptTag.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TBSFGQS"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `

  document.body.prepend(noscriptTag)
})
