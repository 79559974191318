
export const useTracking = () => {
  const { gtag } = useGtag()

  const localeStore = useLocaleStore()
  const {
    locale,
  } = storeToRefs(localeStore)

  const trackPageView = (path) => {
    if (process.env.NODE_ENV === 'development') {
      console.info(`[Tracking] Event PageView path: ${path}`)
    }

    gtag('pageview', path)
  }

  const trackViewContent = ({
    name,
    id,
    value,
    type = 'product',
    currency = 'SEK',
  }) => {
    if (process.env.NODE_ENV === 'development') {
      console.info(`[Tracking] Event ViewContent name: ${name}`)
    }

    gtag('event', 'view_item', {
      currency,
      value,
      content_type: type,
      items: [{
        item_id: id,
        item_name: name,
        affiliation: locale,
      }],
    })

    if (typeof window.ttq?.track === 'function') {
      window.ttq.track('ViewContent', {
        value,
        currency,
        content_type: type,
        contents: [{
          content_id: id,
          content_name: name,
        }],
      })
    }

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'ViewContent', {
        value,
        currency,
        content_ids: [id],
        content_name: name,
        content_type: type,
      })
    }
  }

  const trackAddToCart = ({
    name,
    id,
    value,
    // quantity,
    // price,
    type = 'product',
    currency = 'SEK',
  }) => {
    if (process.env.NODE_ENV === 'development') {
      console.info(`[Tracking] Event AddToCart name: ${name}`)
    }

    gtag('event', 'add_to_cart', {
      currency,
      value,
      content_type: type,
      items: [{
        item_id: id,
        item_name: name,
        affiliation: locale,
        // quantity,
        // price,
      }],
    })

    if (typeof window.ttq?.track === 'function') {
      window.ttq.track('AddToCart', {
        value,
        currency,
        content_type: type,
        contents: [{
          content_id: id,
          content_name: name,
          // quantity,
          // price,
        }],
      })
    }

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'AddToCart', {
        value,
        currency,
        content_ids: [id],
        content_name: name,
        content_type: type,
      })
    }
  }

  const trackInitiateCheckout = ({
    value,
    id,
    name,
    currency = 'SEK',
    type = 'product',
  }) => {
    if (process.env.NODE_ENV === 'development') {
      console.info(`[Tracking] Event InitiateCheckout name: ${name}`)
    }

    gtag('event', 'begin_checkout', {
      currency,
      value,
      items: [{
        item_id: id,
        item_name: name,
        affiliation: locale,
      }],
    })

    if (typeof window.ttq?.track === 'function') {
      window.ttq.track('InitiateCheckout', {
        content_type: type,
        value,
        currency,
        contents: [{
          content_id: id,
          content_name: name,
          affiliation: locale.value,
        }],
      })
    }

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'InitiateCheckout', {
        content_ids: [id],
        value,
        currency,
      })
    }
  }

  const trackClick = (data) => {
    gtag('event', 'click', data)
  }

  const trackCatalogueOrder = (catalogues) => {
    gtag('event', 'catalogue_order', { catalogues })
  }

  const trackNewsletterSignup = (airport) => {
    gtag('event', 'newsletter_signup', { airport })
  }

  return {
    trackPageView,
    trackViewContent,
    trackAddToCart,
    trackInitiateCheckout,
    trackClick,
    trackCatalogueOrder,
    trackNewsletterSignup,
  }
}
