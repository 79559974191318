// Translated
// Migrated not sure if this.$gtag works in nuxt 3 TODO: check if this works
<template lang="pug">
.modal-overlay.cookie-consent-overlay(v-if="open")
  .cookie-consent-banner
    .h3(data-i18n="cookieConsent.title") {{ $t('cookieConsent.title') }}
    p(data-i18n="cookieConsent.description") {{ $t('cookieConsent.description') }}

    .my-3(v-if="customize")
      .d-flex.light-gray-bg.rounded-box.p-3.mb-3(
        v-for="(category, index) in categories"
        :key="index"
      )
        .flex-fill
          .d-flex.align-items-center.consent-category(
            @click="category.expanded = !category.expanded"
          )
            button.btn.btn-link
              fa(:icon="category.expanded ? 'chevron-up' : 'chevron-down'")
            strong(:data-i18n="category.title") {{ $t(category.title) }}

          div(
            v-if="category.expanded"
            :data-i18n="category.details"
          ) {{ $t(category.details) }}

        .d-block(v-if="category.choice")
          label.toggle-check(:for="`rbtc_${category.short}`")
            input(
              :id="`rbtc_${category.short}`"
              v-model="category.active"
              type="checkbox"
            )
            span.toggle
              span.switch

    .d-flex.justify-content-between.flex-wrap.gap-3
      .d-flex
        button.btn.mr-3(
          data-i18n="cookieConsent.customize"
          @click="customize = !customize"
        ) {{ $t('cookieConsent.customize') }}
        button.btn(
          data-i18n="cookieConsent.rejectAll"
          @click="rejectAll"
        ) {{ $t('cookieConsent.rejectAll') }}
      .d-flex
        button.btn.btn-blue.mr-3(
          v-if="customize"
          data-i18n="cookieConsent.saveChoice"
          @click="save"
        ) {{ $t('cookieConsent.saveChoice') }}
        button.btn.btn-orange(
          data-cookie-accept-all
          data-i18n="cookieConsent.acceptAll"
          @click="acceptAll"
        ) {{ $t('cookieConsent.acceptAll') }}
</template>

<script>
/**
 * Handles all logic related to consent, even after accepting/rejecting
 */
export default defineNuxtComponent({
  setup () {
    const { updateUserConsentStatus, refreshGtagConsent, userConsentStatus } = useUserConsent()
    const { trackPageView } = useTracking()

    return {
      trackPageView,
      updateUserConsentStatus,
      userConsentStatus: userConsentStatus.value,
      refreshGtagConsent,
    }
  },

  data () {
    return {
      /**
       * Increase version to re-trigger consent form even if user has accepted before
       * Useful when making changes
       */
      cookieVersion: 1.2,

      open: false,
      customize: false,

      categories: [
        {
          choice: false,
          title: 'cookieConsent.necessary.title',
          details: 'cookieConsent.necessary.description',
          expanded: false,
          short: 'f',
        },
        {
          choice: true,
          title: 'cookieConsent.performance.title',
          details: 'cookieConsent.performance.description',
          expanded: false,
          short: 'p',
          active: false,
        },
        {
          choice: true,
          title: 'cookieConsent.advertising.title',
          details: 'cookieConsent.advertising.description',
          expanded: false,
          short: 'a',
          active: false,
        },
      ],
    }
  },

  mounted () {
    try {
      const isBot = navigator
        && ((navigator.userAgent && /bot|crawl|spider|slurp|teoma/i.test(navigator.userAgent)) || navigator.webdriver)

      if (isBot) {
        return
      }

      if ((this.userConsentStatus.cookieVersion || -1) < this.cookieVersion) {
        this.open = true
      } else {
        this.trackPageView(this.$route.path)
      }
    } catch {
      this.open = true
    }
  },

  methods: {
    handleUserConsentStatus (status, detail) {
      this.updateUserConsentStatus(status, detail)
      this.trackPageView(this.$route.path)
    },

    rejectAll () {
      const rbcs = {
        v: this.cookieVersion,
        t: this.$dayjs().unix(),
      }

      this.categories.forEach((c) => {
        rbcs[c.short] = 0
      })

      this.handleUserConsentStatus(rbcs, { reject: true })

      this.open = false
    },

    acceptAll () {
      const rbcs = {
        v: this.cookieVersion,
        t: this.$dayjs().unix(),
        all: 1,
      }

      this.categories.forEach((c) => {
        rbcs[c.short] = 1
      })

      this.handleUserConsentStatus(rbcs, { accept: true })

      this.open = false
    },

    save () {
      const rbcs = {
        v: this.cookieVersion,
        t: this.$dayjs().unix(),
      }

      this.categories.forEach((c) => {
        rbcs[c.short] = (c.active || !c.choice) ? 1 : 0
      })

      rbcs.all = this.categories.every((c) => rbcs[c.short]) ? 1 : 0

      this.handleUserConsentStatus(rbcs, { rbcs })

      this.open = false
    },
  },
})
</script>

<style lang="scss" scoped>
.toggle-check {
  color: #fff;

  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
  vertical-align: middle;
  margin-bottom: 0;

  cursor: pointer;
}
.toggle-check > input + .toggle > .switch {
  background: #fff;

  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 3;
  box-sizing: border-box;
}
.toggle-check > input + .toggle + .label {
  color: #000;
}
.toggle-check > input:checked + .toggle {
  background: $light-blue;
}
.toggle-check > input:not(:checked) + .toggle {
  background: #ccc;
}
.toggle-check > input:checked + .toggle > .switch {
  border: 3px solid $light-blue;
}
.toggle-check > input:not(:checked) + .toggle > .switch {
  border: 3px solid #ccc;
}
.toggle-check > input:focus + .toggle,
.toggle-check > input:active + .toggle {
  box-shadow: 0 0 5px 3px rgba(0, 119, 200, 0.5);
}
.toggle-check > input + .toggle .switch {
  border-radius: 6px;
}

.toggle-check > input {
  position: absolute;
  opacity: 0;
}
.toggle-check > input + .toggle {
  border-radius: 4px;

  align-items: center;
  position: relative;

  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  width: 60px;
  height: 30px;
  margin: 0;
  cursor: pointer;
}

/* Labels */
.toggle-check > input + .toggle:before,
.toggle-check > input + .toggle:after {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  height: 100%;
}
.toggle-check > input + .toggle:before {
  right: 55%;
}
.toggle-check > input + .toggle:after {
  left: 50%;
}
.toggle-check > input + .toggle + .label {
  margin-left: 10px;
}

/* Show / Hide */
.toggle-check > input + .toggle:before {
  opacity: 0;
}
.toggle-check > input:checked + .toggle:before {
  opacity: 1;
}
.toggle-check > input:checked + .toggle:after {
  opacity: 0;
}

/* Transitions */
.toggle-check > input + .toggle {
  transition: background 150ms linear, box-shadow 150ms linear;
}
.toggle-check > input + .toggle:before,
.toggle-check > input + .toggle:after {
  transition: all 150ms linear;
}
.toggle-check > input + .toggle > .switch {
  transition: right 150ms linear, border-color 150ms linear;
}
/* //////////////////////////
CORE STYLES ABOVE - NO TOUCHY
////////////////////////// */

.toggle-check > input + .toggle > .switch {
  width: 30px;
}
.toggle-check > input + .toggle:before,
.toggle-check > input + .toggle:after {
  font-size: 0.8rem;
}
.toggle-check > input:not(:checked) + .toggle > .switch {
  right: calc(100% - 30px);
}

.modal-overlay {
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(100, 100, 100, 0.5);
}

.cookie-consent-banner {
  position: fixed;
  z-index: 1000000;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  max-width: 95vw;
  padding: 2rem;
  max-height: 90vh;
  overflow: auto;

  border-top: 1px solid $light-gray;
  box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.3);

  background: white;

  .consent-category {
    cursor: pointer;
  }
}
</style>
