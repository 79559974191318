// Translated
// Migrated
<template lang="pug">
.favourite-icon.align-self-end.h-px-40.w-px-40(
  v-if="!hide"
  :class="classes"
  @click.prevent="toggle"
)
  client-only
    .spinner-grow.spinner-grow-sm(v-if="loading")
    img(
      v-else,
      alt="Favourite-mark this trip"
      :src="`/icons/heart-${isFavourite ? 'pink' : 'grey'}.svg`"
      height="40"
      width="40"
    )
</template>

<script>
import { mapActions, mapState } from 'pinia'

import localStorageKeys from '@layers/web/constants/local-storage-keys'
import events from '@layers/web/constants/events.js'
import { loginWhileTogglingFavourite } from '@layers/web/utils/auth/auth'

export default defineNuxtComponent({
  middleware: 'auth',
  auth: false,

  props: {
    id: {
      type: [Number, String],
      default: 0,
    },

    relative: {
      type: Boolean,
      default: false,
    },
  },

  emits: [events.setFavourites, events.loginWithAction],

  data () {
    return {
      favourite: false,
      loading: false,
    }
  },

  computed: {
    ...mapState(useUserStore, {
      favourites: 'favourites',
      isLoggedIn: 'isLoggedIn',
    }),

    ...mapState(useLocaleStore, {
      canLocaleFavorite: 'canLocaleFavorite',
    }),

    hide () {
      return this.id === 0 || !this.canLocaleFavorite
    },

    isFavourite () {
      return this.$isSol
        ? this.favourite
        : this.isFavouriteRolfs
    },

    isFavouriteRolfs () {
      return this.isLoggedIn && this.favourites.map(({ id }) => id).includes(this.id)
    },

    classes () {
      return [
        this.$isSol
          ? 'hotel-favourite-icon'
          : 'trip-favourite-icon',
        this.relative ? '--relative' : '',
      ]
    },
  },

  created () {
    this.packageIsFavouriteSolresor()
  },

  methods: {
    ...mapActions(useUserStore, {
      toggleFavouriteAction: 'toggleFavourite',
    }),

    toggle () {
      if (this.$isSol) {
        this.toggleFavouriteSolresor()
      } else {
        this.toggleFavouriteRolfs()
      }
    },

    packageIsFavouriteSolresor () {
      if (!process.browser) {
        return false
      }
      const favourites = JSON.parse(localStorage.getItem(localStorageKeys.zz.favourites))
      this.favourite = favourites && favourites.includes(this.id)
    },

    toggleFavouriteSolresor () {
      if (!process.browser) {
        return
      }
      this.favourite = !this.favourite
      let favourites = JSON.parse(localStorage.getItem(localStorageKeys.zz.favourites)) || []

      if (favourites.includes(this.id)) {
        favourites = favourites.filter(f => f !== this.id)
      } else {
        favourites.push(this.id)
      }

      localStorage.setItem(localStorageKeys.zz.favourites, JSON.stringify(favourites))
      this.$event.$emit(events.setFavourites, favourites)
    },

    async toggleFavouriteRolfs () {
      this.loading = true
      if (this.isLoggedIn) {
        await this.toggleFavouriteAction(this.id)
      } else {
        this.$event.$emit(events.loginWithAction, loginWhileTogglingFavourite(this.id, this.$t('memberLoginActionFavouriteHint')))
      }
      this.loading = false
    },
  },
})
</script>

<style lang="scss" scoped>
$favourite-bg: rgba(255, 255, 255, .9);
$favourite-border-radius: 0 0 10px 0;
$favourite-border-radius-mobile: 10px 10px 0 0;
$favourite-size: 40px;

.favourite-icon {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  padding: 5px;

  & img {
    height: 20px;
    width: 20px;
  }

  &.--relative{
    position: relative;
  }
  &:hover > img {
    transform: scale(1.25);
  }
}
.trip-favourite-icon {
  top: 0;
  left: 0;
  background: $favourite-bg;
  border-radius: $favourite-border-radius;
}
.hotel-favourite-icon {
  top: .5em;
  right: .5em;

  @media (max-width: ($md - 1px)) {
    // temporary fix, to be back on the right
    top: unset;
    bottom: 0;
    right: 2rem;
    background: $favourite-bg;
    border-radius: $favourite-border-radius-mobile;
  }
}
.hotel-info-favourite {
  .favourite-icon {
    top: calc($favourite-size / 4 * -1) !important;
    right: calc($favourite-size / 2 * -1) !important;

    @media (max-width: ($md - 1px)) {
      left: auto;
      top: calc($favourite-size / 4 * -1) !important;
      right: calc($favourite-size / 2 * -1) !important;
      background-color: transparent !important;
    }
  }
}
</style>
