// Translated
// Migrated
<template lang="pug">
.col-12.article-grid
  .row(
    v-for="(ctrips, index) in filteredTripsColumns"
    :key="`${index}_${ctrips[0].id}`"
  )
    .col-lg-8(v-if="ctrips[0]")
      Trip(:trip="ctrips[0]" size="wide")
    .col-lg-4(v-if="ctrips[1]")
      Trip(:trip="ctrips[1]")
    template(v-if="short")
      .col-lg-4(
        v-for="trip in ctrips.slice(2, 5)",
        :key="`${index}_${trip.id}`"
      )
        Trip(:trip="trip")
    template(v-if="!short && index % 2 != 0")
      .col-lg-4.pb-lg-1em(v-if="ctrips[2]" :key="`${index}_${ctrips[2].id}`")
        Trip.high-item(size="high" :trip="ctrips[2]")
      .col-lg-4(v-if="ctrips[3]" :key="`${index}_${ctrips[3].id}`")
        Trip(:trip="ctrips[3]")
        Trip(v-if="ctrips[4]" :trip="ctrips[4]")
      .col-lg-4(v-if="ctrips[5]" :key="`${index}_${ctrips[5].id}`")
        Trip(:trip="ctrips[5]")
        Trip(v-if="ctrips[6]" :trip="ctrips[6]")
    template(v-if="!short && index % 2 == 0")
      .col-lg-4(v-if="ctrips[3]" :key="`${index}_${ctrips[3].id}`")
        Trip(:trip="ctrips[3]")
        Trip(v-if="ctrips[4]" :trip="ctrips[4]")
      .col-lg-4(v-if="ctrips[5]" :key="`${index}_${ctrips[5].id}`")
        Trip(:trip="ctrips[5]")
        Trip(v-if="ctrips[6]" :trip="ctrips[6]")
      .col-lg-4.pb-lg-1em(v-if="ctrips[2]" :key="`${index}_${ctrips[2].id}`")
        Trip.high-item(size="high" :trip="ctrips[2]")
</template>

<script>
export default defineNuxtComponent({
  props: {
    short: {
      type: Boolean,
      default: false,
    },

    trips: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredTripsColumns () {
      if (!this.trips?.length) {
        return []
      }

      const trips = []
      const pageSize = 7

      for (let i = 0; i < this.trips.length / pageSize; i++) {
        trips.push(this.trips.slice(i * pageSize, (i + 1) * pageSize))
      }

      return trips
    },
  },
})
</script>
