export const useMenuStore = defineStore('menu', {
  state: () => ({
    sideMenuActive: false,
    alertHidden: false,
    hamburgerMenuActive: false,
    loadingMenu: false,
  }),
  getters: {},
  actions: {
    toggleSideMenuActive () {
      this.sideMenuActive = !this.sideMenuActive
      this.hamburgerMenuActive = false
    },

    toggleHamburgerMenuActive () {
      this.hamburgerMenuActive = !this.hamburgerMenuActive
      this.sideMenuActive = false
    },

    closeSideMenuActive () {
      this.hamburgerMenuActive = false
      this.sideMenuActive = false
    },

    SET_ALERT_HIDDEN (hidden) {
      this.alertHidden = hidden
    },

    SET_MENU_LOADING (status) {
      this.loadingMenu = status
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot))
}