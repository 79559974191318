export const whitelabelConfigs = {
  nazar: {
    name: 'nazar',
    logo: 'logoNazar',
    referrerUrl: 'https://www.nazar.se/',
    primaryColor: '#4CB7CD',
    allowedDestinations: [20, 21],
    market: ['zz'],
    webCode: 'efftpvxv1381',
  },
}