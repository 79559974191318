const originalFetchRaw = $fetch.raw

export default defineNuxtPlugin((nuxtApp) => {
  const { $sentryCaptureMessage } = nuxtApp

  $fetch.raw = async (url, options = {}) => {
    const response = await originalFetchRaw(url, options)

    const contentType = response.headers.get('content-type') || ''
    if (!contentType.includes('application/json') || typeof response._data === 'string') {
      $sentryCaptureMessage(`FATAL: Non-JSON response from ${url}`, {
        level: 'fatal',
        extra: {
          url,
          request: options,
          response,
          status: response.status,
          statusText: response.statusText,
          contentType,
        },
      })
    }

    return response
  }
})