import dayjs from 'dayjs'
import { type CookieOptions, useCookie, useNuxtApp } from '#app'

import type { StorageLike, ExpirationOptions } from '@packages/persiststate/runtime/types.ts'

function usePersistedstateCookies (cookieOptions?: CookieOptions<string>): StorageLike {
  return {
    getItem: (key) => {
      return useCookie<string>(key, {
        ...cookieOptions,
        readonly: false,
        encode: encodeURIComponent,
        decode: decodeURIComponent,
      }).value
    },
    setItem: (key, value) => {
      useCookie<string>(key, {
        ...cookieOptions,
        readonly: false,
        encode: encodeURIComponent,
        decode: decodeURIComponent,
      }).value = value
    },
  }
}

function usePersistedstateLocalStorage (): StorageLike {
  return {
    getItem: (key) => {
      return !useNuxtApp().ssrContext
        ? localStorage.getItem(key)
        : null
    },
    setItem: (key, value) => {
      if (!useNuxtApp().ssrContext)
        localStorage.setItem(key, value)
    },
  }
}

function usePersistedstateSessionStorage (): StorageLike {
  return {
    getItem: (key) => {
      return !useNuxtApp().ssrContext
        ? sessionStorage.getItem(key)
        : null
    },
    setItem: (key, value) => {
      if (!useNuxtApp().ssrContext)
        sessionStorage.setItem(key, value)
    },
  }
}

function usePersistedstateLocalStorageWithExpiration (expirationOptions: ExpirationOptions): StorageLike {
  const { renewOnUpdate, expiration } = {
    renewOnUpdate: true,
    ...expirationOptions,
  }

  const expirationStorageKeySuffix = 'exp'

  const isExpired = (expirationStorageKey: string) => {
    const expirationValue = localStorage.getItem(expirationStorageKey)
    return typeof expirationValue !== 'string' || parseInt(expirationValue) < dayjs().unix()
  }

  return {
    getItem: (key) => {
      if (useNuxtApp().ssrContext) {
        return null
      }

      const expirationStorageKey = `${key}_${expirationStorageKeySuffix}`

      if (!isExpired(expirationStorageKey)) {
        return localStorage.getItem(key)
      }

      localStorage.removeItem(key)
      localStorage.removeItem(expirationStorageKey)

      return null
    },
    setItem: (key, value) => {
      if (useNuxtApp().ssrContext) {
        return
      }

      const expirationStorageKey = `${key}_${expirationStorageKeySuffix}`

      if (renewOnUpdate || localStorage.getItem(expirationStorageKey) === null) {
        const expirationSeconds = expiration * .001
        localStorage.setItem(expirationStorageKey, (dayjs().unix() + expirationSeconds).toString())
      }

      if (isExpired(expirationStorageKey)) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, value)
      }
    },
  }
}

export const persistedState = {
  localStorage: usePersistedstateLocalStorage(),
  localStorageWithExpiration: usePersistedstateLocalStorageWithExpiration,
  sessionStorage: usePersistedstateSessionStorage(),
  cookies: usePersistedstateCookies(),
  cookiesWithOptions: usePersistedstateCookies,
}
