import { whitelabelConfigs } from '@layers/web/constants/whitelabel'

export const useWhitelabelStore = defineStore('whitelabel', {
  state: () => ({
    whitelabel: null,
  }),
  getters: {
    getWhitelabel (state) {
      const wl = state.whitelabel
      if (wl && Object.prototype.hasOwnProperty.call(whitelabelConfigs, wl)) {
        return whitelabelConfigs[wl]
      }
      return null
    },

    getWhitelabelConfig: (state) => () => {
      return whitelabelConfigs[state.whitelabel] || null
    },

    getAllWebCodes () {
      return Object.values(whitelabelConfigs)
        .filter(item => item && item.webCode)
        .map(item => item.webCode)
    },
  },
  actions: {
    SET_WHITE_LABEL (value) {
      if (!value) {
        return
      }
      const localeStore = useLocaleStore()
      const rootStore = useRootStore()
      const locale = localeStore.locale
      const whitelabelConfig = whitelabelConfigs[value]

      if (whitelabelConfig && whitelabelConfig.market.includes(locale)) {
        console.info('Whitelabel found and allowed for this market', value)
        this.whitelabel = value
        rootStore.handleAgentCookie(whitelabelConfig.webCode)
      } else {
        console.info('Whitelabel not found or not allowed for this market', value)
        this.whitelabel = false
      }
    },

    checkAgentCookie () {
      const rootStore = useRootStore()
      const allWhitelabelAgentCodes = this.getAllWebCodes
      const agentCookie = useCookie('a')

      if (allWhitelabelAgentCodes.includes(agentCookie?.value?.code) && !this.whitelabel) {
        agentCookie.value = null

        rootStore.SET_SHOWOFFICETOOLS(false)
        rootStore.SET_SHOWAGENTTOOLS(false)
        rootStore.SET_TRANSLATIONTOOLS(false)
        rootStore.SET_CMSTOOLS(false)
        rootStore.SET_AGENTCOOKIE(false)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWhitelabelStore, import.meta.hot))
}