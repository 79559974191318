// jwt-decode plugin
import { jwtDecode } from 'jwt-decode'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      jwtDecode: (token: string) => jwtDecode(token),
    },
  }
})
