import { LOCALES_LANG_INFO, SWEDISH_LOCALES, type LocaleLangInfo } from '@packages/locale/constants/lang.ts'
import type { Locale } from '@packages/locale/types/locales.ts'

export const localeToLangInfo = (locale: Locale): LocaleLangInfo => {
  let langLocale = locale

  if (SWEDISH_LOCALES.includes(locale)) {
    langLocale = 'sv'
  }

  const localeLangInfo = LOCALES_LANG_INFO[langLocale]

  if (!localeLangInfo) {
    throw new Error('Unknown locale: ' + locale)
  }

  return localeLangInfo
}

export const localeToLang = (locale: Locale) => localeToLangInfo(locale).lang
