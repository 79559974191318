
// eslint-disable-next-line require-await
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!import.meta.browser){
    return
  }

  const rootStore = useRootStore()
  if (from.name) {
    rootStore.previousRoute = from
  }
})